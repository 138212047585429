import { IStructuredSchemaSettings } from '@shared/types/umbraco';

import { getStringWithoutTags, removeExtraSlashes } from 'utils/stringUtils/stringUtils';

import {
  INewsArticleSchema,
  IOrganizationSchema,
  IProductSchema,
  IWebpageSchema,
  StructuredSchemasType,
  ISchemaScriptData,
  IWebpageDto,
  IOrganizationDto,
  IProductDto,
  INewsArticleDto,
  DynamicSchemaValuesType,
  IBreadCrumbsDto,
  IBreadcrumbsSchema,
  IDrugSchema,
  IDrugDto,
} from './model';

export class SchemaService {
  readonly schemaTypes = {
    WebPage: 'WebPage',
    Product: 'Product',
    NewsArticle: 'NewsArticle',
    Organization: 'Organization',
    BreadcrumbList: 'BreadcrumbList',
    Drug: 'Drug',
  } as const;

  private entityTypes = {
    context: 'https://schema.org',
    brand: 'Brand',
    webPage: 'WebPage',
    imageObject: 'ImageObject',
    organization: 'Organization',
    contactPoint: 'ContactPoint',
    postalAddress: 'PostalAddress',
    listItem: 'ListItem',
    drugClass: 'DrugClass',
  } as const;

  getStructuredSchemas = (
    schemas: string[],
    structuredSchemaSettings: IStructuredSchemaSettings,
    dynamicValues: DynamicSchemaValuesType
  ): ISchemaScriptData[] =>
    schemas
      .filter((type) => this.schemaTypes[type])
      .map((type) =>
        this.getSchemaScriptData(
          this.schemasMap[type]({ ...structuredSchemaSettings, ...dynamicValues })
        )
      );

  getSchemaScriptData = (schema: StructuredSchemasType): ISchemaScriptData => ({
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schema),
  });

  getWebpageSchema = ({
    brand,
    pageUrl,
    siteUrl,
    webPageLogoUrl,
  }: IWebpageDto): IWebpageSchema => ({
    '@context': this.entityTypes.context,
    '@type': this.schemaTypes.WebPage,
    name: brand,
    mainEntityOfPage: {
      '@type': this.entityTypes.webPage,
      '@id': removeExtraSlashes(siteUrl + pageUrl),
    },
    publisher: {
      '@type': this.entityTypes.brand,
      name: brand,
      url: siteUrl,
      logo: {
        '@type': this.entityTypes.imageObject,
        url: webPageLogoUrl,
      },
    },
  });

  getOrganizationSchema = ({
    brand,
    siteUrl,
    organizationLogoUrl,
    organizationDescription,
    telephone,
    contactType,
    streetAddress,
    addressLocality,
    postalCode,
    addressCountry,
    sameAs,
  }: IOrganizationDto): IOrganizationSchema => ({
    '@context': this.entityTypes.context,
    '@type': this.schemaTypes.Organization,
    name: brand,
    url: siteUrl,
    Logo: organizationLogoUrl,
    description: organizationDescription,
    contactPoint: [
      {
        '@type': this.entityTypes.contactPoint,
        telephone,
        contactType,
      },
    ],
    address: {
      '@type': this.entityTypes.postalAddress,
      streetAddress,
      addressLocality,
      postalCode,
      addressCountry,
    },
    sameAs: sameAs?.map(({ value }) => value),
  });

  getProductSchema = ({
    brand,
    siteUrl,
    entityTitle,
    entityImageUrl,
    pageUrl,
    entityDescription,
    color,
    size,
  }: IProductDto): IProductSchema => ({
    '@context': this.entityTypes.context,
    '@type': this.schemaTypes.Product,
    name: getStringWithoutTags(entityTitle),
    image: entityImageUrl,
    url: removeExtraSlashes(siteUrl + pageUrl),
    description: entityDescription,
    brand: {
      '@type': this.entityTypes.brand,
      name: brand,
    },
    manufacturer: {
      '@type': this.entityTypes.organization,
      name: brand,
    },
    color,
    size,
  });

  getNewsArticleSchema = ({
    brand,
    siteUrl,
    pageUrl,
    entityTitle,
    entityImageUrl,
    articleLogoUrl,
    entityDescription,
    introduction,
    createDate,
    updateDate,
  }: INewsArticleDto): INewsArticleSchema => ({
    '@context': this.entityTypes.context,
    '@type': this.schemaTypes.NewsArticle,
    mainEntityOfPage: {
      '@type': this.entityTypes.webPage,
      '@id': removeExtraSlashes(siteUrl + pageUrl),
    },
    headline: getStringWithoutTags(entityTitle),
    image: [entityImageUrl],
    dateModified: updateDate,
    datePublished: createDate,
    author: {
      '@type': this.entityTypes.brand,
      name: brand,
    },
    publisher: {
      '@type': this.entityTypes.organization,
      name: brand,
      logo: {
        '@type': this.entityTypes.imageObject,
        url: articleLogoUrl,
      },
    },
    description: entityDescription,
    articleBody: getStringWithoutTags(introduction),
  });

  getBreadcrumbsSchema = ({ siteUrl, breadcrumbs }: IBreadCrumbsDto): IBreadcrumbsSchema => ({
    '@context': this.entityTypes.context,
    '@type': this.schemaTypes.BreadcrumbList,
    itemListElement: breadcrumbs?.map(({ name, link }, idx) => ({
      '@type': this.entityTypes.listItem,
      position: idx + 1,
      item: {
        '@id': removeExtraSlashes(siteUrl + link),
        name: name?.trim(),
      },
    })),
  });

  getDrugSchema = ({
    entityTitle,
    entityDescription,
    entityIngredients,
    drugClass,
  }: IDrugDto): IDrugSchema => ({
    '@context': this.entityTypes.context,
    '@type': this.schemaTypes.Drug,
    name: getStringWithoutTags(entityTitle),
    description: entityDescription,
    activeIngredient: entityIngredients,
    drugClass: {
      '@type': this.entityTypes.drugClass,
      name: drugClass,
    },
  });

  private schemasMap = {
    [this.schemaTypes.WebPage]: this.getWebpageSchema,
    [this.schemaTypes.NewsArticle]: this.getNewsArticleSchema,
    [this.schemaTypes.Product]: this.getProductSchema,
    [this.schemaTypes.Organization]: this.getOrganizationSchema,
    [this.schemaTypes.BreadcrumbList]: this.getBreadcrumbsSchema,
    [this.schemaTypes.Drug]: this.getDrugSchema,
  };
}

export const schemaService = new SchemaService();
