import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { schemaService } from 'utils/schemaService';

import { IStructuredPageSchemaProps } from './model';

const StructuredPageSchema: FC<IStructuredPageSchemaProps> = ({
  additionalStructuredSchemas,
  structuredSchemaSettings,
  dynamicStructuredSchemaValues,
}) => (
  <Helmet
    script={schemaService.getStructuredSchemas(
      [schemaService.schemaTypes.WebPage, ...(additionalStructuredSchemas || [])],
      structuredSchemaSettings,
      dynamicStructuredSchemaValues
    )}
  />
);

export default StructuredPageSchema;
