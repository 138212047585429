import React, { FC } from 'react';

import { IPropsDesktopNavigation } from './model';

import DesktopNavItem from '../DesktopNavItem';

import './DesktopNavigation.scss';

const DesktopNavigation: FC<IPropsDesktopNavigation> = ({ navigation }) => (
  <ul className="desktop-nav" data-test="desktopNavigation">
    {navigation.map((element) => (
      <DesktopNavItem key={element.properties.label} {...element} />
    ))}
  </ul>
);

export default DesktopNavigation;
