import React, { FC, useState, useCallback } from 'react';
import classNames from 'classnames';

import Container from 'layout/Container';
import LanguageSelector from 'layout/Header/LanguageSelector';
import MobileNavigation from 'layout/Header/MobileNavigation';
import SearchBar from 'layout/Header/SearchBar';
import DesktopNavigation from 'layout/Header/DesktopNavigation';
import Logo from 'common/Logo';
import IconCustom from 'common/IconCustom';
import ButtonWithIcon from 'common/ButtonWithIcon';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { ELEMENTS } from 'utils/constants';

import Helpers from './heplers';
import { IPropsHeader } from './model';

import './Header.scss';
import './ShadowHeader.scss';

const Header: FC<IPropsHeader> = ({
  brandSettings,
  header: {
    navigation,
    languageSelectorAriaLabel,
    closeSearchFormAriaLabel,
    searchIconAriaLabel,
    searchIconFormAriaLabel,
    searchBarText,
    searchBarTextClose,
    searchRedirect,
    isHideSearch,
    burgerAriaLabel,
    externalLink,
  },
  searchPlaceholder,
  searchTitle,
  urls,
  languages,
  currentLanguageLetters,
  pageLogo,
  pageLogoAlt,
}) => {
  const [closeBurger, setCloseBurger] = useState<boolean>(false);
  const [closeSearchBar, setCloseSearchBar] = useState<boolean>(false);
  const [openLanguageSelector, setOpenLanguageSelector] = useState<boolean>(false);

  const parsedLanguages = useCallback(
    () => Helpers.parseLanguagesToArray(languages, urls, currentLanguageLetters),
    [languages, urls, currentLanguageLetters]
  );

  const languagesHeader: any = Object.values(parsedLanguages());

  const handlerOpenMenu = useCallback(() => setCloseBurger((prev) => !prev), []);

  const handlerOpenSearchBar = useCallback(() => {
    if (openLanguageSelector) {
      setOpenLanguageSelector(false);
    }
    setCloseSearchBar((prev) => !prev);
  }, [openLanguageSelector]);

  const handlerVisibleLanguageSelector = useCallback(() => {
    if (!closeSearchBar) {
      setCloseSearchBar(false);
    }
    setOpenLanguageSelector((prev) => !prev);
  }, [closeSearchBar]);

  const handlerCloseLanguageSelector = useCallback(
    (languageSelectorStateB: boolean) => () => setOpenLanguageSelector(languageSelectorStateB),
    []
  );

  const handlerCloseSearchBar = useCallback(
    (searchBarState: boolean) => () => setCloseSearchBar(searchBarState),
    []
  );

  const { isDesktop } = useScreenRecognition();

  return (
    <header
      data-test="header"
      id={ELEMENTS.HEADER_ID}
      className={classNames('header', { 'fixed-header ': closeBurger })}
    >
      <Container className="header__container">
        {!isDesktop && !externalLink?.length ? (
          <LanguageSelector
            handlerVisibleLanguageSelector={handlerVisibleLanguageSelector}
            handlerCloseLanguageSelector={handlerCloseLanguageSelector}
            openLanguageSelector={openLanguageSelector}
            languageSelectorAriaLabel={languageSelectorAriaLabel}
            currentLanguageLetters={currentLanguageLetters}
            languagesHeader={languagesHeader}
          />
        ) : null}
        <div className="header__left-pack">
          <Logo brandSettings={brandSettings} pageLogo={pageLogo} pageLogoAlt={pageLogoAlt} />
          {isDesktop ? <DesktopNavigation navigation={navigation} /> : null}
        </div>
        <div className="header__right-pack">
          {isDesktop ? (
            <LanguageSelector
              handlerVisibleLanguageSelector={handlerVisibleLanguageSelector}
              handlerCloseLanguageSelector={handlerCloseLanguageSelector}
              openLanguageSelector={openLanguageSelector}
              languageSelectorAriaLabel={languageSelectorAriaLabel}
              currentLanguageLetters={currentLanguageLetters}
              languagesHeader={languagesHeader}
            />
          ) : navigation?.length ? (
            <button
              aria-label={burgerAriaLabel}
              type="button"
              className="right-pack__burger"
              onClick={handlerOpenMenu}
            >
              <span className={classNames('burger-span', { 'burger--open': closeBurger })} />
            </button>
          ) : null}

          {externalLink?.length ? (
            <ButtonWithIcon
              link={externalLink[0].properties.link[0].url}
              target={externalLink[0].properties.link[0].target}
              customClass="external-link"
              ariaLabel={externalLink[0].properties.linkAriaLabel}
              name={externalLink[0].properties.link[0].name}
              icon={externalLink[0].properties.icon[0]}
            />
          ) : null}

          {!isHideSearch ? (
            <button
              aria-label={searchIconAriaLabel}
              type="button"
              className="right-pack__search"
              onClick={handlerOpenSearchBar}
            >
              <IconCustom
                icon="search"
                className="right-pack__search-icon"
                id={ELEMENTS.SEARCH_TRIGGER_ID}
              />
            </button>
          ) : null}
        </div>
      </Container>
      <div className="mobile-navigation__bottom-pack">
        {closeSearchBar ? (
          <SearchBar
            handler={handlerOpenSearchBar}
            searchRedirect={searchRedirect}
            handlerCloseSearchBar={handlerCloseSearchBar}
            closeSearchBar={closeSearchBar}
            isDesktop={isDesktop}
            closeSearchFormAriaLabel={closeSearchFormAriaLabel}
            searchIconFormAriaLabel={searchIconFormAriaLabel}
            searchPlaceholder={searchPlaceholder}
            searchBarText={searchBarText}
            searchBarTextClose={searchBarTextClose}
            searchTitle={searchTitle}
            clickOutsideExceptionsIds={[ELEMENTS.SEARCH_TRIGGER_ID]}
          />
        ) : null}

        {!isDesktop ? <MobileNavigation {...{ closeBurger, navigation }} /> : null}
      </div>
    </header>
  );
};

export default Header;
